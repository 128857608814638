import { CircuitConfig } from "@/components/CircuitSelector/CircuitManager";
import {extractParams} from "@/components/CircuitSelector/CircuitAnalyzer";
import { ImpedanceData } from "./Impedance";


export class Result{
    isDone = false;
    fit = -1.0;
    f_calc:number[] = [];
    rs_calc:number[] = [];
    x_calc:number[] = [];
    params:{[key:string]:string} = {};
}


export const fit = function(data: ImpedanceData, config: CircuitConfig){
    const params: {[key:string]:string} = {};

    //mock
    return new Promise((resolve, reject) => {
        //init params
        extractParams(config.nodes).forEach(p_str=>{
            params[p_str] = "0";
        });

        fetch("https://33hiyo6b3j.execute-api.ap-northeast-1.amazonaws.com/v1/fitting", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                input: JSON.stringify({
                    method: "01",
                    f: data.impedance.f,
                    rs: data.impedance.rs,
                    x: data.impedance.x
                }),
                stateMachineArn: "arn:aws:states:ap-northeast-1:837132241748:stateMachine:FittingStateMachine"
            })
        }).then(response=>{
            return response.json();
        }).then(result=>{
            const execution_arn:string = result.executionArn;
            return new Promise((resolve2, reject2)=>{
                let retry = 0;
                const timer = setInterval(()=>{
                    fetch("https://33hiyo6b3j.execute-api.ap-northeast-1.amazonaws.com/v1/fitting/status", {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            executionArn: execution_arn
                        })
                    }).then(res=>{
                        retry += 1;
                        return res.json()
                    }).then((result)=>{
                        if(retry > 30){
                            clearInterval(timer);
                            reject2();
                        }
                        if(result.status === "SUCCEEDED"){
                            clearInterval(timer);
                            resolve2(JSON.parse(result.output));
                        }
                    });
                }, 10000);
            });
        }).then((result:any)=>{
            if(result.result!="OK"){
                throw new Error("Fitting Result not good");
            }

            const top_result = result.data[0];
            //retract results
            data.fitting_result.isDone = true;
            data.fitting_result.fit = top_result.fit[0];
            data.fitting_result.params = top_result.params;

            data.fitting_result.f_calc = top_result.f_calc;
            data.fitting_result.rs_calc = top_result.rs_calc;
            data.fitting_result.x_calc = top_result.x_calc;

            resolve(top_result);
        }).catch(()=>{
            reject();
        })
    });
}