import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'
import CircuitFittingView from '../views/CircuitFittingView.vue'
import AboutView from "../views/AboutView.vue";
import ImpedanceGeneratorView from "../views/ImpedanceGenerator.vue";
import ContactView from "../views/ContactView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'equivalent_circuit_fitting',
    component: CircuitFittingView
  },
  {
    path: '/drt',
    name: 'distribution_of_relaxation_times',
    component: AboutView
  },
  {
    path: '/impedance',
    name: 'impedance_spectra_simulation',
    component: ImpedanceGeneratorView
  },
  {
    path: '/contact',
    name: 'tell_request',
    component: ContactView
  }
];

export const router: Router = createRouter({
  history: createWebHistory(),
  routes
});