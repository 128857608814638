import { reactive, InjectionKey } from 'vue';

const csvConfig = reactive({
    "StartRow": 1,
    "FreqCol": 1,
    "ReZCol": 2,
    "ImZCol": 3,
    "DecimalPoint": "."
});

export interface CSVConfig{
    StartRow: number;
    FreqCol: number;
    ReZCol: number;
    ImZCol: number;
    DecimalPoint: string;
}

interface ConfigStore {
    csvConfig: CSVConfig;
}

export default {
    csvConfig,
};

export const configInjectionKey: InjectionKey<ConfigStore> = Symbol("ConfigKey");

export const defaultConfig:ConfigStore = {
    "csvConfig": {
        "StartRow": 2,
        "FreqCol": 1,
        "ReZCol": 2,
        "ImZCol": 3,
        "DecimalPoint": "."
    }
};