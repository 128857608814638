import {Complex} from "@/components/Utility/complex";

function priority(char: string){
    switch(char){
        case "/":
            return 2;
        case "-":
            return 1;
        case "(":
        case ")":
            return -1;
        default:
            return 0;
    }
}

export class CNode{
    name: string;
    type: string;
    left: CNode | null;
    right: CNode | null;
    comment: string;

    constructor(name: string, type: string){
        this.name = name;
        this.type = type;
        this.left = null;
        this.right = null;
        this.comment = "";
    }
}
export const extractParams = function(node: CNode|null):string[] {
    if(!node){
        return [];
    }
    if(node.type==="operator"){
        return extractParams(node.left)?.concat(extractParams(node.right));
    }
    return [node.name];
}

export const parse = (input:string)=>{
    if(!input){
        return undefined;
    }

    const stack: string[] = [];
    const expression: string[] = [];

    //------
    // 逆ポーランド記法に変換 (expressionに格納)
    //------

    for(const s of input.replace(/\s/g, "")){
        //console.log("Step " + s, expression, stack);

        const p = priority(s);
        if(p===0){
            expression.push(s);
            continue;
        }else{
            expression.push(" ");
        }

        if(s===")"){
            while(stack.length>0){
                const popped = stack.pop();
                if(popped?.replace(/\s/g, "") === "("){
                    break;
                }else if(popped){
                    expression.push(popped);
                    expression.push(" ");
                }
            }
            continue;
        }
        if(s==="("){
            stack.push(" " + s + " ");
            continue;
        }
        
        while(stack.length > 0 && priority(stack[stack.length-1].replace(/\s/g, "")) > p){
            expression.push(stack.pop() as string);
        }
    

        stack.push(" " + s + " ");

    }
    while(stack.length>0){
        expression.push(stack.pop() as string);
    }

    //------
    // 二分木に変換
    //------

    const tokens = expression.join("").split(" ").filter(token=>token);//半角スペース2つとかだと出てくるempty stringをsplit結果から取り除く
    //console.log(tokens);
    const buffer: CNode[] = [];//working memory
    let unknownCheck = false;

    tokens.forEach(token=>{
        switch(token){
            case "/":
            case "-":{
                const node = new CNode(token, "operator");
                node.right = buffer.pop() as CNode;
                node.left = buffer.pop() as CNode;
                buffer.push(node);
                break;
            }
            default:
                switch(token.substring(0,1)){
                    case "R":
                        buffer.push(new CNode(token, "R"));
                        break;
                    case "C":
                        buffer.push(new CNode(token, "C"));
                        break;
                    case "L":
                        buffer.push(new CNode(token, "L"));
                        break;
                    default:
                        unknownCheck = true;
                        buffer.push(new CNode(token, "?"));
                        break;
                }
                break;
        }
    });
    if(buffer.length > 1){
        console.error("failed to create binary tree");
        return undefined;
    }else{
        if(unknownCheck){
            buffer[0].comment = "unknown included";
        }
        return buffer[0];//root element
    }
}


//-----------------
// svg drawing
// canvas size for each element: 120x60
//-----------------

interface Direction{
    elements: SVGElement[];
    boundingBox: {
        x: number;
        y: number;
    }
}

function drawR(node:CNode) :Direction{
    const output = document.createElementNS("http://www.w3.org/2000/svg", "path");
    output.setAttribute("d", "M 0,30 30,30 35,10 45,50 55,10 65,50 75,10 85,50 90,30 120,30");
    output.setAttribute("stroke", "black");
    output.setAttribute("stroke-width", "4");
    output.setAttribute("fill", "rgba(0,0,0,0)");
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "90");
    text.setAttribute("y", "25");
    text.setAttribute("stroke", "black");
    text.setAttribute("stroke-width", "0.5");
    text.setAttribute("text-anchor", "start");
    text.setAttribute("font-size", "16pt");
    text.textContent = node.name;
    return {
        elements: [output, text],
        boundingBox:{
            x: 120,
            y: 60
        }
    }
}

function drawL(node:CNode): Direction{
    const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const line2 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line1.setAttribute("x1", "0");
    line1.setAttribute("y1", "30");
    line1.setAttribute("x2", "30");
    line1.setAttribute("y2", "30");
    line1.setAttribute("stroke", "black");
    line1.setAttribute("stroke-width", "4");
    line2.setAttribute("x1", "90");
    line2.setAttribute("y1", "30");
    line2.setAttribute("x2", "120");
    line2.setAttribute("y2", "30");
    line2.setAttribute("stroke", "black");
    line2.setAttribute("stroke-width", "4");
    const circle1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    const circle2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    const circle3 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    const circle4 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    
    circle1.setAttribute("d", "M 30 30 A 7.5 7.5 0 1 1 45 30");
    circle1.setAttribute("stroke", "black");
    circle1.setAttribute("stroke-width", "4");
    circle1.setAttribute("fill", "rgba(0,0,0,0)");
    circle2.setAttribute("d", "M 45 30 A 7.5 7.5 0 1 1 60 30");
    circle2.setAttribute("stroke", "black");
    circle2.setAttribute("stroke-width", "4");
    circle2.setAttribute("fill", "rgba(0,0,0,0)");
    circle3.setAttribute("d", "M 60 30 A 7.5 7.5 0 1 1 75 30");
    circle3.setAttribute("stroke", "black");
    circle3.setAttribute("stroke-width", "4");
    circle3.setAttribute("fill", "rgba(0,0,0,0)");
    circle4.setAttribute("d", "M 75 30 A 7.5 7.5 0 1 1 90 30");
    circle4.setAttribute("stroke", "black");
    circle4.setAttribute("stroke-width", "4");
    circle4.setAttribute("fill", "rgba(0,0,0,0)");
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "90");
    text.setAttribute("y", "20");
    text.setAttribute("stroke", "black");
    text.setAttribute("stroke-width", "0.5");
    text.setAttribute("text-anchor", "start");
    text.setAttribute("font-size", "16pt");
    text.textContent = node.name;
    return {
        elements: [line1, line2, circle1, circle2, circle3, circle4, text],
        boundingBox:{
            x: 120,
            y: 60
        }
    }
}

function drawC(node: CNode): Direction{
    const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const line2 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const line3 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const line4 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line1.setAttribute("x1", "0");
    line1.setAttribute("y1", "30");
    line1.setAttribute("x2", "55");
    line1.setAttribute("y2", "30");
    line1.setAttribute("stroke", "black");
    line1.setAttribute("stroke-width", "4");
    line2.setAttribute("x1", "55");
    line2.setAttribute("y1", "0");
    line2.setAttribute("x2", "55");
    line2.setAttribute("y2", "60");
    line2.setAttribute("stroke", "black");
    line2.setAttribute("stroke-width", "4");
    line3.setAttribute("x1", "65");
    line3.setAttribute("y1", "0");
    line3.setAttribute("x2", "65");
    line3.setAttribute("y2", "60");
    line3.setAttribute("stroke", "black");
    line3.setAttribute("stroke-width", "4");
    line4.setAttribute("x1", "65");
    line4.setAttribute("y1", "30");
    line4.setAttribute("x2", "120");
    line4.setAttribute("y2", "30");
    line4.setAttribute("stroke", "black");
    line4.setAttribute("stroke-width", "4");
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "75");
    text.setAttribute("y", "25");
    text.setAttribute("stroke", "black");
    text.setAttribute("stroke-width", "0.5");
    text.setAttribute("text-anchor", "start");
    text.setAttribute("font-size", "16pt");
    text.textContent = node.name;
    return {
        elements: [line1, line2, line3, line4, text],
        boundingBox:{
            x: 120,
            y: 60
        }
    }
}

function drawUnknown(node: CNode){
    const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line1.setAttribute("x1", "0");
    line1.setAttribute("y1", "30");
    line1.setAttribute("x2", "120");
    line1.setAttribute("y2", "30");
    line1.setAttribute("stroke", "red");
    line1.setAttribute("stroke-width", "4");
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "60");
    text.setAttribute("y", "25");
    text.setAttribute("stroke", "black");
    text.setAttribute("stroke-width", "0.5");
    text.setAttribute("text-anchor", "start");
    text.setAttribute("font-size", "16pt");
    text.textContent = node.name;
    return {
        elements: [line1, text],
        boundingBox:{
            x: 120,
            y: 60
        },
        offset:{
            x: 0,
            y: 0
        }
    }
}

function drawComponent(node: CNode):Direction{
    const margin = 10;
    switch(node.type){
        case "operator":{
            if(!node.right || !node.left){
                throw new DOMException("invalid operation");
            }
            const leftElems = drawComponent(node.left);
            const rightElems = drawComponent(node.right);
            switch(node.name){

                case "/":{
                    const bx = Math.max(leftElems.boundingBox.x, rightElems.boundingBox.x);

                    //線を補充する
                    [leftElems, rightElems].forEach(elems=>{
                        if(elems.boundingBox.x < bx){
                            const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
                            line.setAttribute("x1", `${elems.boundingBox.x}`);
                            line.setAttribute("y1", "30");
                            line.setAttribute("x2", `${bx}`);
                            line.setAttribute("y2", "30");
                            line.setAttribute("stroke", "black");
                            line.setAttribute("stroke-width", "4");
                            elems.elements.push(line);
                            elems.boundingBox.x = bx;
                        }
                    });

                    rightElems.elements.forEach(elem=>{
                        const translate = elem.getAttribute("transform");
                        if(!translate){
                            elem.setAttribute("transform", `translate(0, ${leftElems.boundingBox.y + margin})`);
                        }else{
                            const values = elem.getAttribute("transform")?.split("(")[1];
                            if(!values){
                                throw new DOMException("failed to fetch transform property");
                            }
                            const translate_x = parseInt(values.split(",")[0]);
                            const translate_y = parseInt(values.split(",")[1].slice(0,-1));

                            elem.setAttribute("transform", `translate(${translate_x}, ${translate_y + leftElems.boundingBox.y + margin})`)
                        }
                       

                        
                    });

                    //縦棒追加
                    const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
                    line1.setAttribute("x1", `0`);
                    line1.setAttribute("y1", "30");
                    line1.setAttribute("x2", `0`);
                    line1.setAttribute("y2", `${leftElems.boundingBox.y + margin + 30}`);
                    line1.setAttribute("stroke", "blue");
                    line1.setAttribute("stroke-width", "4");
                    const line2 = document.createElementNS("http://www.w3.org/2000/svg", "line");
                    line2.setAttribute("x1", `${bx}`);
                    line2.setAttribute("y1", "30");
                    line2.setAttribute("x2", `${bx}`);
                    line2.setAttribute("y2", `${leftElems.boundingBox.y + margin + 30}`);
                    line2.setAttribute("stroke", "blue");
                    line2.setAttribute("stroke-width", "4");

                    const elems = leftElems.elements.concat(rightElems.elements);
                    elems.push(line1);
                    elems.push(line2);

                    return {
                        elements: elems,
                        boundingBox:{
                            x: bx,
                            y: leftElems.boundingBox.y + 10 + rightElems.boundingBox.y
                        }
                    }
                }
                case "-":{
                    const by = Math.max(leftElems.boundingBox.y, rightElems.boundingBox.y);

                    rightElems.elements.forEach(elem=>{
                        const translate = elem.getAttribute("transform");
                        if(!translate){
                            elem.setAttribute("transform", `translate(${leftElems.boundingBox.x + margin}, 0)`);
                        }else{
                            const values = elem.getAttribute("transform")?.split("(")[1];
                            if(!values){
                                throw new DOMException("failed to fetch transform property");
                            }
                            const translate_x = parseInt(values.split(",")[0]);
                            const translate_y = parseInt(values.split(",")[1].slice(0,-1));

                            elem.setAttribute("transform", `translate(${leftElems.boundingBox.x + margin + translate_x}, ${translate_y})`);
                        }
                       
                    });

                    //connector
                    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
                    line.setAttribute("x1", `0`);
                    line.setAttribute("y1", "30");
                    line.setAttribute("x2", `10`);
                    line.setAttribute("y2", "30");
                    line.setAttribute("stroke", "blue");
                    line.setAttribute("stroke-width", "4");
                    line.setAttribute("transform", `translate(${leftElems.boundingBox.x}, 0)`);

                    const elems = leftElems.elements.concat(rightElems.elements);
                    elems.push(line);
                    return {
                        elements: elems,
                        boundingBox:{
                            x: leftElems.boundingBox.x + 10 + rightElems.boundingBox.x,
                            y: by
                        }
                    }
                }
                default:
                    return drawUnknown(node);
            }
        }
        case "R":
            return drawR(node);
        case "C":
            return drawC(node);
        case "L":
            return drawL(node);
        default:
            return drawUnknown(node);
    }
}

export const drawSVG = (root?: CNode|undefined) =>{
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    if(!root){
        return svg;
    }
    const components = drawComponent(root);
    svg.setAttribute("viewBox", `0 0 ${components.boundingBox.x} ${components.boundingBox.y}`);
    svg.setAttribute("width", `100%`);
    svg.setAttribute("height", `100%`);

    //svg.setAttribute("width", `${components.boundingBox.x}`);
    //svg.setAttribute("height", `${components.boundingBox.y}`);
    
    components.elements.forEach(elem=>{
        svg.appendChild(elem);
    });
    return svg;
};