export class Complex{
    real = 0.0;
    imag = 0.0;

    constructor(r:number, i:number){
        this.real = r;
        this.imag = i;
    }

    static add = (x: Complex, y:Complex)=>{
        return new Complex(x.real + y.real, x.imag + y.imag);
    }

    static sub = (x: Complex, y:Complex)=>{
        return new Complex(x.real - y.real, x.imag - y.imag);
    }

    static mul = (x: Complex, y:Complex)=>{
        return new Complex(x.real * y.real - x.imag * y.imag, x.real * y.imag + x.imag * y.real);
    }
    
    static inv = (x: Complex)=>{
        const denom = (x.real * x.real + x.imag * x.imag);
        if(denom === 0){
            throw new Error("zero division error");
        }
        return new Complex(x.real / denom, -x.imag / denom);
    }
}