import { Directive } from "vue";

// v-click-outside directive
//
//https://stackoverflow.com/questions/36170425/detect-click-outside-element
//

export const clickOutside:Directive = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event:MouseEvent) => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            binding.value(event, el);
        }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};