<template>
    <div>

    
    なんかフォームとか
    <input type="text">
    <textarea></textarea>
    <button>Send!</button>
    SNS共有リンクとか
</div>
</template>

<style scoped>
    div{
        display: flex;
        flex-direction: column;
    }
</style>