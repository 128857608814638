import { reactive, readonly, InjectionKey } from 'vue';
import {v4 as uuidv4} from "uuid";
import Papa from "papaparse";
import { CSVConfig } from './ConfigManager';
import { ImpedanceData } from './Impedance';
import { Result } from './Fitting';

const files = reactive({} as FileStorageObject);
const register = function(file:File, csvConfig:CSVConfig){
    let key = uuidv4();
    while(key in files){
        key = uuidv4();
    }
    files[key] = {
        file,
        status: "registered",
        parsedData: [[]],
        impedance: {f: [], rs: [], x:[]},
        under_fitting: false,
        fitting_result: new Result(),
        drt: {}
    };
    console.log("registered:" + key);

    file.text().then(txt=>{
        //頭とお尻の改行がある場合は消去
        txt = txt.replace(/^[\r?\n]+|[\r?\n]+$/g,'');

        //ファイルを(一応)読めた場合
        const result = Papa.parse(txt);
        if(result.errors.length>0){
            files[key].status = "parse failed";
            return;
        }
        files[key].parsedData = result.data as string[][];

        //f,r,xを読み込む
        

        if(result.data.length < csvConfig.StartRow-1){
            files[key].status = "extract failed";
            return;
        }
        if(result.data.some(row =>(row as string[]).length < Math.max(csvConfig.FreqCol, csvConfig.ImZCol, csvConfig.ReZCol))){
            files[key].status = "extract failed2";
            return;
        }

        const arr_str_f = result.data.map(row=>(row as string[])[csvConfig.FreqCol-1]).slice(csvConfig.StartRow-1);
        const arr_str_rez = result.data.map(row=>(row as string[])[csvConfig.ReZCol-1]).slice(csvConfig.StartRow-1);
        const arr_str_imz = result.data.map(row=>(row as string[])[csvConfig.ImZCol-1]).slice(csvConfig.StartRow-1);
        
        console.log(arr_str_rez);

        //todo: 小数点チェック工程

        const arr_f = arr_str_f.map(d=>parseFloat(d));
        const arr_rez = arr_str_rez.map(d=>parseFloat(d));
        const arr_imz = arr_str_imz.map(d=>parseFloat(d));

        if(arr_f.some(d=>isNaN(d)) || arr_rez.some(d=>isNaN(d)) || arr_imz.some(d=>isNaN(d))){
            files[key].status = "extract failed3";
            return;
        }
        
        files[key].impedance.f = arr_f;
        files[key].impedance.rs = arr_rez;
        files[key].impedance.x = arr_imz;
        files[key].status = "OK";

        //console.log(result);
    }).catch(err=>{
        //ファイルが読み込めなかった場合。フォルダを入れたり…
        console.log(err);
        files[key].status = "read failed";
    })
};
const remove = function(key:string){
    if(key in files){
        return delete(files[key]);
    }
    return false;
}

const size = function(){
    return Object.keys(files).length;
}

const keys = function(){
    return Object.keys(files);
}

const set_status = function(key:string, st:string){
    if(!(key in files)){
        return false;
    }
    files[key].status = st;
    return true;
}


interface FileStorageObject{
    [key: string]: ImpedanceData;
}

interface FileStore {
    files: FileStorageObject;
    register: (file:File, option:CSVConfig) => void;
    remove: (key:string) => boolean;
    size: ()=>number;
    keys: ()=>string[];
    set_status: (key:string, st:string)=>boolean;
}

export default {
    files,
    register,
    remove,
    size,
    keys,
    set_status
};

export const key: InjectionKey<FileStore> = Symbol("key");