import { reactive, InjectionKey } from 'vue';
import { v4 as uuidv4} from "uuid";
import { CNode, parse } from "@/components/CircuitSelector/CircuitAnalyzer";


const node = parse("R0-Rl/L-R1/C1-R2/C2-R3/C3-R4/C4-R5/C5");

const circuits = reactive([{
    id: "preset01",
    schematic: "R0-Rl/L-R1/C1-R2/C2-R3/C3-R4/C4-R5/C5",
    algorithm: "Linear",
    comment: "Linear circuit",
    selected: true,
    nodes: (node)?node:new CNode("", "?")
}]);

export interface CircuitConfig{
    id: string;
    schematic: string;
    algorithm: string;
    comment: string;
    selected: boolean;
    nodes: CNode;
}

interface CircuitConfigStore {
    circuits: CircuitConfig[];
    add_circuit: (schematic:string, comment:string, algorithm?:string)=>void;
}

const add_circuit = (schematic: string, comment: string, algorithm = "default")=>{
    const id = uuidv4();
    const nodes = parse(schematic);
    circuits.push({
        id,
        schematic,
        algorithm,
        comment,
        selected: false,
        nodes: (nodes)?nodes:new CNode("", "?")
    });
};

export default {
    circuits,
    add_circuit
};

export const circuitConfigInjectionKey: InjectionKey<CircuitConfigStore> = Symbol("CircuitConfigKey");

export const defaultCircuits:CircuitConfigStore = {
    circuits,
    add_circuit
};