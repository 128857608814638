<template>
    <div></div>
</template>

<style scoped>
div {
  content: '';
  width: 10px;
  height: 5px;
  border-left: 2px solid #25AF01;
  border-bottom: 2px solid #25AF01;
  transform: rotate(-45deg);
}

</style>