import { reactive, readonly, InjectionKey } from 'vue';
import {v4 as uuidv4} from "uuid";

const toasts = reactive({} as NotificationObject);

let transitionTimeIn = 500;//ms
let transitionTimeOut = 500;//ms

const push = function(text: string, type:string, duration:number, title = ""){
    let id = uuidv4();
    while(id in toasts){
        id = uuidv4();
    }

    toasts[id] = {
        id,
        title,
        text,
        type,
        status: "created"
    };

    if(duration > 0){
        setTimeout(()=>{
            if(id in toasts){
                toasts[id].status = "showing";
                setTimeout(()=>{
                    if(id in toasts){
                        toasts[id].status = "expired";
                        setTimeout(()=>{
                            if(id in toasts){
                                delete toasts[id];
                            }
                        }, transitionTimeOut);
                    }
                }, duration);
            }
        }, transitionTimeIn);
    }
    else{//permanent type
        setTimeout(()=>{
            if(id in toasts){
                toasts[id].status = "showing";
            }
        }, transitionTimeIn);
    }
};

/* interfaces */
const success = function(text: string, title="", duration:number){
    push(text, "success", duration, title);
}
const info = function(text: string, title="", duration:number){
    push(text, "info", duration, title);
}
const warning = function(text: string, title="", duration:number){
    push(text, "warning", duration, title);
}
const alert = function(text: string, title="", duration:number){
    push(text, "alert", duration, title);
}

const toList = function(){
    return Object.keys(toasts).map(key=>toasts[key]).slice().reverse();
}

const length = function(){
    return Object.keys(toasts).length;
}

const remove = function(id:string){
    if(id in toasts){
        toasts[id].status = "expired";
        setTimeout(()=>{
            if(id in toasts){
                delete toasts[id];
            }
        }, transitionTimeOut);
    }
}

interface Notification{
    id: string;
    title: string,
    type: string;
    text: string;
    status: string;
}

interface NotificationObject{
    [id: string]: Notification;
}

interface ToastPool {
    toasts: NotificationObject;
    success: (text:string, title:string, duration:number)=>void;
    info: (text:string, title:string, duration:number)=>void;
    warning: (text:string, title:string, duration:number)=>void;
    alert: (text:string, title:string, duration:number)=>void;
    toList: ()=>Notification[];
    length: ()=>number;
    remove: (id:string)=>void;
}

export const setupToaster = (in_time: 500, out_time: 500)=>{
    transitionTimeIn = (in_time > 0) ? in_time: 500;
    transitionTimeOut = (out_time > 0) ? out_time: 500;
    return {
        toasts: readonly(toasts),
        success, info, warning, alert,
        toList,
        length,
        remove
    }
};

export const toastInjectionKey: InjectionKey<ToastPool> = Symbol("toastInjectionKey");