import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { loadLocaleResources, setI18nLanguage, getCookieLocale,  } from '@/language/i18n';
import { setupI18n, DEFAULT_LOCALE, SUPPORTED_LOCALES, SET_LOADED_LOCALES } from '@/language/i18n';

import { clickOutside } from './components/Utility/clickOutside';

export const i18n = setupI18n();

router.beforeEach((to, from, next)=>{
    //ページタイトルを設定
    const title = to.name as string;
    if(!title){
        document.title = "EIS Studio";
    }else{
        document.title = "EIS Studio | " + title;
    }

    //まずクエリパラメータから言語を取得
    //取得できない又は指定がない場合はCookieから取得
    //そこもなかったら、デフォルト言語を設定
    let locale = to.query.locale as string;
    if(!locale){
        const cookieLocale = getCookieLocale();
        if(cookieLocale){
            locale = cookieLocale;
        }
        else{
            locale = DEFAULT_LOCALE;
        }
    }
    
    //指定言語がサポートしてる言語かどうか確認する。
    //サポートしていなかったらデフォルト言語を強制する
    if(!SUPPORTED_LOCALES.includes(locale)){
        locale = DEFAULT_LOCALE;
    }

    //設定する
    setI18nLanguage(i18n, locale);
    
    //言語リソース読み込み済みか確認
    if(!SET_LOADED_LOCALES.has(locale)){
        //読み込まれていなかったら読み込んで戻る
        return loadLocaleResources(i18n, locale).then(()=>next());
    }else{
        //読み込まれてたらそのままでOK
        return next();
    }
});


createApp(App).use(router).use(i18n).directive("click-outside", clickOutside).mount('#app');
