import { nextTick } from 'vue';
import { createI18n, I18n, VueI18n } from "vue-i18n";

export const SUPPORTED_LOCALES = ["en", "ja"];
export const SET_LOADED_LOCALES = new Set();
export const DEFAULT_LOCALE = "en";
const LOCALE_COOKIE_KEY = "locale";

export function setupI18n(){
  let locale:string|undefined = DEFAULT_LOCALE;
  if(getCookie(LOCALE_COOKIE_KEY)){
    locale = getCookie(LOCALE_COOKIE_KEY) as string;
  }

  const i18n = createI18n({
    locale: locale
  });

  setI18nLanguage(i18n, locale);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: string) {
    i18n.global.locale = locale;
    document.querySelector("html")?.setAttribute("lang", locale);
    document.cookie = `${LOCALE_COOKIE_KEY}=${locale};Secure=True;SameSite=None`;
}

export async function loadLocaleResources(i18n: I18n, locale:string) {
  const resource = await import(`./locales/${locale}.json`);
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, resource);
  SET_LOADED_LOCALES.add(locale);
  return nextTick();
}

export function getCookieLocale(){
  if(getCookie(LOCALE_COOKIE_KEY)){
    return getCookie(LOCALE_COOKIE_KEY);
  }else{
    return undefined;
  }
}

export function getCurrentLocale(i18n_global: VueI18n){
  return i18n_global.locale;
}

function getCookie(key :string) :string|undefined{
  const result = document.cookie.split("; ").find(c=>c.split("=")[0]===key);
  if(result){
    return result.split("=")[1];
  }else{
    return undefined;
  }
}